.box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .lottie {
      width: 80%;
    }

    .tip {
      font-size: 18px;
      font-weight: 700;
      // color: #555;
    }
  }
}
@primary-color: #C2313A;@text-color: #000000;@text-color-secondary: #7C7C89;@border-radius-base: 4px;@border-color-base: #D7DDE4;@link-color: #3399FF;@success-color: #00CC66;@fail-color: #FF3300;@warning-color: #FF9900;@error-color: #FF0000;@font-size-base: 14px;@background-color: #FFFFFF;