// @import '~antd/dist/antd.less';
// @import '~antd/dist/reset.css';

* {
  padding: 0;
  margin: 0;

  // 全局使用noto-sans字体
  font-family: 'noto';

  /* 自定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸 */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    margin-bottom: 10px;
    // background: #f2f2f2;
    background: #cacaca;
    border-radius: 8px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    background: fade(#fff, 20%);
    border-radius: 10px;
    box-shadow: none;
  }

  /* Antd-Select选中的Option */
  // .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  //   color: rgba(0, 0, 0, 0.85);
  //   font-weight: 600;
  //   background-color: #f9eeee;
  // }

  // antd message样式
  .ant-message {
    top: 15vh !important;
  }

  .ant-message-notice-content {
    min-width: 300px;
    min-height: 80px;
    padding: 0 16px;
    line-height: 80px;
    font-size: 16px;
    border-radius: 4px;
  }

  .ant-modal-wrap {
    text-align: center;
  }

  .ant-modal-wrap::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
  }

  .ant-modal {
    top: 0;
    display: inline-block;
    padding-bottom: 0;
    text-align: start;
    vertical-align: middle;
  }
}

.scrollbarScreen() {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    margin-bottom: 10px;
    background: #d6d6d660;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: fade(#f2f2f2, 20%);
    border-radius: 10px;
    box-shadow: none;
  }
}

@primary-color: #C2313A;@text-color: #000000;@text-color-secondary: #7C7C89;@border-radius-base: 4px;@border-color-base: #D7DDE4;@link-color: #3399FF;@success-color: #00CC66;@fail-color: #FF3300;@warning-color: #FF9900;@error-color: #FF0000;@font-size-base: 14px;@background-color: #FFFFFF;